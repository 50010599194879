import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { AemComponentEntity, ProductEntity } from 'constants/data-shapes/product-entity';

import AddAllItemsButton from 'components/AddAllItemsButton/Browse';
import Breadcrumbs from 'components/Breadcrumbs';

import CategoryLinks from 'components/CategoryLinks';
import PageSubTitle from 'components/PageSubTitle';
import PageTitle from 'components/PageTitle';
import ContentLocation from 'components/ContentLocation';
import root from 'window-or-global';
import AmendOrderBanner from './AmendOrderBanner';

import styles from './BrowseHeader.scss';

const prodScene7 = 'https://waitrose-prod.scene7.com/is/image/waitroseprod/';
const nonprodScene7 = 'https://waitrose-test.scene7.com/is/image/waitrosenonprod/';

const BrowseHeader = ({
  AddAllItemsComponent,
  breadcrumbs,
  hideBreadcrumbs,
  hideContent,
  isCustomAisle,
  isGLP,
  isChristmasHub,
  linksLabel,
  products,
  showTitle,
  splitTitle,
  subCategories,
  subTitle,
  title,
}) => {
  const hasSubcategories =
    (subCategories && subCategories.length > 0 && products && products.length > 0) ||
    (subCategories && subCategories.length > 0 && isChristmasHub);
  const showBreadcrumbs = !hideBreadcrumbs && breadcrumbs;
  const hasSubTitle = subTitle && subTitle.display && !!subTitle.text;

  const isProd = root?.environment?.apiEnv === 'prod';

  const subTitleProps = {
    ...subTitle,
  };

  const titleProps = {
    ...title,
    uppercase: !isCustomAisle,
  };

  return (
    <header className={styles.browseHeader}>
      {showBreadcrumbs && (
        <Breadcrumbs
          baseUrl={isCustomAisle ? '/ecom/shop/featured' : '/ecom/shop/browse'}
          breadcrumbs={breadcrumbs}
          currentPage={title.text}
        />
      )}
      {isChristmasHub && (
        <>
          <div className="container-fluid">
            <img
              alt="christmas hub header"
              className={styles.headerImage}
              src={`${
                isProd ? prodScene7 : nonprodScene7
              }header-image?$Waitrose-Image-Preset-95$&wid=586`}
            />
          </div>
          <AmendOrderBanner />
        </>
      )}
      <div className="container-fluid">
        {showTitle && splitTitle && (
          <Fragment>
            <PageTitle {...titleProps} underline name="split-page-title" />
            {hasSubTitle && <PageSubTitle {...subTitleProps} />}
          </Fragment>
        )}
      </div>
      {!hideContent && <ContentLocation name="masthead" preloadImages />}
      <div className="container-fluid">
        {showTitle && !splitTitle && (
          <Fragment>
            <PageTitle {...titleProps} underline={!hasSubcategories} />
            {hasSubTitle && <PageSubTitle {...subTitleProps} />}
          </Fragment>
        )}
        {hasSubcategories && (
          <CategoryLinks
            componentId="subcategories-navigation"
            isGLP={isGLP}
            isChristmasHub={isChristmasHub}
            categories={subCategories}
            label={linksLabel}
            ariaLabel={`List of sub-categories within ${title.text}`}
            title={title}
          />
        )}

        <AddAllItemsComponent />
      </div>
    </header>
  );
};

BrowseHeader.propTypes = {
  AddAllItemsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      internal: PropTypes.bool,
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
  ),
  hideBreadcrumbs: PropTypes.bool,
  hideContent: PropTypes.bool,
  isCustomAisle: PropTypes.bool,
  isGLP: PropTypes.bool,
  isChristmasHub: PropTypes.bool,
  linksLabel: PropTypes.shape({
    display: PropTypes.bool,
    text: PropTypes.string,
  }),
  products: PropTypes.arrayOf(PropTypes.oneOfType([ProductEntity(), AemComponentEntity()])),
  showTitle: PropTypes.bool,
  splitTitle: PropTypes.bool,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  subTitle: PropTypes.shape({
    display: PropTypes.bool,
    text: PropTypes.string,
  }),
  title: PropTypes.shape({
    text: PropTypes.string,
  }),
};

BrowseHeader.defaultProps = {
  AddAllItemsComponent: AddAllItemsButton,
  breadcrumbs: [],
  hideBreadcrumbs: false,
  hideContent: false,
  isCustomAisle: false,
  isGLP: false,
  isChristmasHub: false,
  linksLabel: {},
  products: [],
  showTitle: true,
  splitTitle: false,
  subCategories: [],
  subTitle: {},
  title: {},
};

BrowseHeader.displayName = 'BrowseHeader';

export default BrowseHeader;
