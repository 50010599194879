import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { viewOrderURL } from 'constants/urls';
import { dayjs } from 'utils/date';
import classNames from 'classnames';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { customerSlotSelector } from 'redux/modules/customer-slot/selectors';
import { BREAKPOINTS } from 'constants/grid';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';

import styles from './AmendOrderBanner.scss';

const AmendOrderBanner = () => {
  const { slotDate, slotStartTime, slotEndTime } = useSelector(customerSlotSelector);

  const customerSlotDate = dayjs(slotDate).format('D MMMM');
  const customerSlotStartTime = dayjs(slotStartTime, 'HH:mm:ss').format('h');
  const customerSlotEndTime = dayjs(slotEndTime, 'HH:mm:ss').format('ha');
  const orderId = useSelector(getCustomerOrderId);
  const isMobileOrTablet = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.sm });

  return (
    <div className={classNames(styles.white, 'container-fluid')}>
      <div className={classNames(styles.wrapper, { [styles.mobile]: isMobileOrTablet })}>
        <div className={classNames(styles.orderNumber, { [styles.mobile]: isMobileOrTablet })}>
          <Typography styleAs="headingLarge" noMargins>
            You&apos;re amending order no. {orderId}
          </Typography>
        </div>
        <div className={classNames(styles.dateAndCTA, { [styles.mobile]: isMobileOrTablet })}>
          <Typography styleAs="sectionHeading" noMargins={!isMobileOrTablet}>
            Scheduled for{' '}
            <b>
              {customerSlotStartTime}-{customerSlotEndTime} on {customerSlotDate}
            </b>
          </Typography>
          <LinkAsButton
            component={Link}
            to={viewOrderURL(orderId)}
            theme="secondary"
            label="View order"
            width={isMobileOrTablet ? 'full' : 'standard'}
          />
        </div>
      </div>
    </div>
  );
};

export default AmendOrderBanner;
