import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { match } from 'path-to-regexp';

import env from 'env/env';
import locator from 'utils/locator';
import { canonical } from './canonicalRoutes';

const removeTrailingSlash = url => url.replace(/\/+$/, '');

const Canonical = ({ absolute, href, location: { pathname } = locator }) => {
  const isCanonical = !absolute && canonical.length && !!match(canonical)(pathname);

  let url = href;

  if (isCanonical) {
    // Create self-referencing canonical tag
    url = `https://${env.prodHost}${pathname}`.toLowerCase();
  }

  return url ? <Helmet link={[{ rel: 'canonical', href: removeTrailingSlash(url) }]} /> : null;
};

Canonical.propTypes = {
  absolute: PropTypes.bool,
  href: PropTypes.string,
};

export default Canonical;
